import { ListGroupItem, ListGroup } from 'react-bootstrap'

import { NumberInput } from 'apps/shared/components/FormInputFields'
import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const Modal = styled.div`
    > * {
        padding-top: 10px;
    }
`

export const ArticleList = styled(ListGroup)`
    display: inline-block;
    width: 100%;
`

export const RadioButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
    gap: 8px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;

    > * > * {
        cursor: pointer;
    }
`

export const NumberInputWrapper = styled(NumberInput)`
    font-size: 0.8rem;
`

export const LicenseListItem = styled(ListGroupItem)`
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    border: none;
    max-height: 200px;

    #info-section {
        width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        > span {
            display: grid;
            grid-template-columns: 1fr;
            max-height: 100%;
        }

        > textarea {
            min-height: 23px;
            width: 90%;
            flex-grow: 1;
            border-color: ${secondary.lightGray};
            border-radius: 0.375rem;
        }
    }

    > :last-child {
        display: flex;
        align-items: flex-start;
        height: 100%;
        padding-top: 38px;

        > :first-child {
            width: 60px;
            height: 23px;
        }

        > :last-child {
            cursor: pointer;
            margin-left: 16px;
            margin-top: 6px;
        }
    }
`

export const TotalPriceSection = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    font-weight: bold;
    margin-top: 8px;
`

export const PriceAdjustmentSection = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`

export const ArticleSetWrapper = styled.div`
    display: grid;
    grid-template-rows: auto auto;

    font-size: 0.8rem;
    border: 1px solid ${secondary.lightGray};
    border-radius: 0.375rem;

    padding-bottom: 0;
    margin-bottom: 0.8rem;
`
