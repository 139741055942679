import React, { useState } from 'react'

import getLicenses from 'api/licenses/getLicenses'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import BooleanIcon from 'apps/shared/components/Icons/BooleanIcon'
import TextButton from 'apps/vendor/components/Buttons'
import { LicenseModal } from 'apps/vendor/components/Modals'
import { PartnerType } from 'apps/vendor/components/PartnerList'
import { LicensesTable } from 'apps/vendor/components/Tables'
import {
    CustomerType,
    LicenseType,
    MarginStructureType,
} from 'apps/vendor/interfaces/subscriptions'

import { ArticleType } from '../Articles/Articles'
import PageHeader from '../PageHeader'
import { AccordionWrapper, BadgeRow, HeaderRow } from '../pages.styled'

export default function Licenses(props: {
    updateLicenses: (data: LicenseType[]) => void
    licenses: LicenseType[]
    articles: ArticleType[]
    partners: PartnerType[]
    customers: CustomerType[]
    marginStructures: MarginStructureType[]
}) {
    const {
        licenses,
        articles,
        partners,
        customers,
        marginStructures,
        updateLicenses,
    } = props
    const [showLicenseModal, setShowLicenseModal] = useState(false)

    const [selectedLicense, setSelectedLicense] = useState<LicenseType | null>(
        null,
    )

    const updateLicenseList = () => {
        getLicenses().then((response) => {
            updateLicenses(response.data)
        })
    }

    const onLicenseModalSubmit = () => {
        updateLicenseList()
        setSelectedLicense(null)
        setShowLicenseModal(false)
    }

    const handleSetSelectedLicense = (license: LicenseType) => {
        setSelectedLicense(license)
        setShowLicenseModal(!showLicenseModal)
    }

    const getAccordionHeaders = (license: LicenseType) => {
        return (
            <BadgeRow numberOfColumns={3}>
                <TextButton
                    onClick={() => handleSetSelectedLicense(license)}
                    text={license.name}
                />
                <span>{license.license_key}</span>
                <span className="centered">
                    <BooleanIcon checked={license.active} />
                </span>
            </BadgeRow>
        )
    }

    const getAccordionBody = (license: LicenseType) => {
        return (
            <LicensesTable
                license={license}
                setSelectedLicense={handleSetSelectedLicense}
            />
        )
    }

    const renderTable = () => {
        if (!licenses) {
            return null
        }

        if (licenses.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                <HeaderRow numberOfColumns={3}>
                    <span>Name</span>
                    <span>License Key</span>
                    <span className="centered">Active</span>
                </HeaderRow>
                <AccordionWrapper alwaysOpen>
                    {licenses.map((license, index) => (
                        <AccordionItem
                            key={license.license_key}
                            eventKey={index.toString()}
                            header={getAccordionHeaders(license)}
                            body={getAccordionBody(license)}
                        />
                    ))}
                </AccordionWrapper>
            </>
        )
    }

    return (
        <div>
            <PageHeader
                onButtonClicked={() => setShowLicenseModal(!showLicenseModal)}
            />
            {showLicenseModal && (
                <LicenseModal
                    onClose={() => {
                        setShowLicenseModal(!showLicenseModal)
                        setSelectedLicense(null)
                    }}
                    onSubmit={() => onLicenseModalSubmit()}
                    articles={articles}
                    marginStructures={marginStructures}
                    partners={partners}
                    preselectedLicense={selectedLicense}
                    customers={customers}
                />
            )}
            {renderTable()}
        </div>
    )
}
