import React from 'react'

import { BadgeWrapper } from './StatusIcon.styled'

interface IconProps {
    type?: 'success' | 'warning' | 'danger'
    text?: string
}

export default function StatusIcon(defaultProps: IconProps) {
    const { type, text } = defaultProps

    return <BadgeWrapper bg={type || 'secondary'}>{text}</BadgeWrapper>
}
