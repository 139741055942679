import PhoneInput from 'react-phone-input-2'

import styled from 'styled-components'

export const PhoneInputWrapper = styled(PhoneInput)`
    > div .selected-flag {
        margin-right: 2px;
    }
    > .form-control {
        width: 100%;
        font-size: 1rem;
    }
`
