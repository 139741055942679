import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const Grid = styled.div`
    @media screen and (min-width: 1023px) {
        display: grid;
        grid-template-columns: 1fr 32vw;
    }
    @media screen and (max-width: 1023px) {
        display: flex;
        flex-direction: column;
    }

    position: fixed;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;
`

export const Image = styled.div`
    flex: 1;

    #sharlicWhiteLogo,
    #sharlicLogo {
        z-index: 5;
        height: 16vh;
        margin: 25px 0px 0px 25px;
    }
    @media screen and (max-width: 1023px) {
        flex: none;
        margin: 25px;
    }

    #sharlicWhiteLogo {
        @media screen and (max-width: 1023px) {
            display: none;
        }
    }

    #sharlicLogo {
        @media screen and (min-width: 1023px) {
            display: none;
        }
        @media screen and (max-width: 1023px) {
            height: 80%;
            min-width: 171px;
        }
    }

    #bubbles {
        z-index: -20;
        height: 100vh;
        position: absolute;
        left: 0;

        @media screen and (max-width: 1023px) {
            display: none;
        }
    }

    @media screen and (max-width: 1023px) {
        height: 6em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const RegisterForm = styled.div`
    padding: 0 6vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    z-index: 10;
    flex: 0 0 320px;

    h1 {
        font-size: max(1.5em, 2vw);
        text-align: center;
    }

    form > * {
        font-size: max(0.8em, 1vw);
        height: 1.8em;

        :not(button):not(div) {
            margin: 0.5em 0;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid ${secondary.lightGray};
            padding: 0 2px;

            :focus {
                border-radius: 5px;
                outline: none;
                background-color: ${secondary.almostWhiteGray};
            }
        }
    }

    h1:first-child {
        justify-content: center;
    }

    form {
        display: grid;
    }

    button {
        background-color: ${primary.blue};
        color: white;
        height: 3em;
        border: none;

        :hover {
            background-color: ${primary.lightBlue};
        }

        :active {
            background-color: ${primary.darkBlue};
        }
    }

    select {
        background-color: white;
    }

    #footer {
        font-size: max(0.6em, 0.8vw);
        text-align: center;
        color: ${primary.darkGray};
        a {
            color: ${primary.blue};
            font-weight: 600;
        }
    }

    @media screen and (max-width: 1023px) {
        flex: 0 0 auto;
        padding: 0 20vw 0 20vw;

        h1 {
            font-size: 1.5em;
        }

        form > * {
            font-size: 1.2em;
            height: 1.5em;
        }
    }

    @media screen and (max-width: 500px) {
        padding: 0 10vw 0 10vw;
        min-width: 250px;
    }
`

export const Space = styled.div`
    height: 0;

    @media screen and (min-height: 500px) {
        height: 23vh;
    }

    @media screen and (min-height: 400px) {
        height: 2vh;
    }
`
