import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'

import { editLicense, addLicense } from 'api/licenses'
import getPartnersMarginStructures from 'api/partners/getPartnerMarginStructures'
import getPartnersArticles from 'api/partners/getPartnersArticles'
import {
    NumberInput,
    ResponsiveTextInput,
} from 'apps/shared/components/FormInputFields'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { Modal } from 'apps/shared/components/Modals'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import {
    ErrorMessage,
    FormLabel,
    Switch,
    WarningMessage,
} from 'apps/shared/shared.styled'
import { PartnerType } from 'apps/vendor/components/PartnerList'
import {
    CustomerType,
    LicenseType,
    MarginStructureType,
    SalesTierType,
} from 'apps/vendor/interfaces/subscriptions'
import { ArticleType } from 'apps/vendor/pages/Articles'
import { localeNumber } from 'apps/vendor/utils'
import useToast from 'hooks/useToast'
import useVendor from 'hooks/useVendor'

import ArticlesDropdownContainer from '../../ArticlesDropdownContainer'
import {
    Checkbox,
    DoubleSection,
    DropdownContainer,
    MissingAssetText,
    ModalWrapper,
    RemoveListItemButton,
} from '../Modals.styled'
import {
    LicenseListItem,
    NumberInputWrapper,
    RadioButtons,
    PriceAdjustmentSection,
    TotalPriceSection,
    ArticleList,
    ArticleSetWrapper,
} from './LicenseModal.styled'

export type ArticleSetType = {
    quantity: number
    article: ArticleType
    description?: string
}

type LicenseModalProps = {
    onClose: () => void
    onSubmit: () => void
    articles: ArticleType[]
    marginStructures: MarginStructureType[]
    partners: PartnerType[]
    preselectedLicense?: LicenseType | null
    customers: CustomerType[]
}

export default function LicenseModal(defaultProps: LicenseModalProps) {
    const {
        onClose,
        onSubmit,
        articles,
        marginStructures,
        partners,
        preselectedLicense,
        customers,
    } = defaultProps
    const { vendor } = useVendor()
    const [name, setName] = useState(preselectedLicense?.name || '')

    const [licenseKey, setLicenseKey] = useState(
        preselectedLicense?.license_key || '',
    )
    const [licenseKeyError, setLicenseKeyError] = useState('')

    const [usePartnerArticles, setUsePartnerArticles] = useState(
        (preselectedLicense &&
            preselectedLicense.articles[0].article.vendor !== vendor?.id) ||
            false,
    )

    const [selectedPartner, setSelectedPartner] = useState<PartnerType | null>(
        partners[
            partners.findIndex(
                (partner) =>
                    partner.id.toString() ===
                    preselectedLicense?.articles[0].article.vendor.toString(),
            )
        ] || null,
    )

    const [selectedCustomer, setSelectedCustomer] = useState<
        CustomerType | undefined
    >(
        customers.find(
            (customer) => customer.id === Number(preselectedLicense?.customer),
        ) || undefined,
    )

    const [selectedArticles, setSelectedArticles] = useState<ArticleSetType[]>(
        preselectedLicense?.articles || [],
    )

    const [articleSetDescriptionErrors, setArticleSetDescriptionErrors] =
        useState<{
            [key: number]: string
        }>({})

    const [filteredArticles, setFilteredArticles] = useState<
        ArticleType[] | null
    >([])
    const [articleList, setArticleList] = useState<ArticleType[] | null>(null)
    const [articleError, setArticleError] = useState('')

    const [selectedMarginStructure, setSelectedMarginStructure] =
        useState<MarginStructureType | null>(
            preselectedLicense?.margin_structure || null,
        )

    const [selectedSalesTiers, setSelectedSalesTiers] = useState<
        SalesTierType[]
    >(preselectedLicense?.sales_tiers || [])

    const [salesTierError, setSalesTierError] = useState('')

    const [marginStructureList, setMarginStructureList] = useState<
        MarginStructureType[]
    >([])

    const [selectedStartDate, setSelectedStartDate] = useState(
        preselectedLicense?.start_date || new Date().toISOString().slice(0, 10),
    )

    const [selectedInvoiceStartDate, setSelectedInvoiceStartDate] = useState(
        preselectedLicense?.sharlic_invoice_period_start_date ||
            new Date().toISOString().slice(0, 10),
    )

    const [active, setActive] = useState<boolean>(
        preselectedLicense ? preselectedLicense.active : true,
    )

    const [selectedPaymentFrequency, setSelectedPaymentFrequency] = useState(
        preselectedLicense?.payment_frequency || 'MONTHLY',
    )

    const [selectedTerm, setSelectedTerm] = useState(
        preselectedLicense?.term || 'MONTH',
    )

    const [priceAdjustmentPercentage, setPriceAdjustmentPercentage] = useState<
        number | string
    >(
        Number(preselectedLicense?.rebate) ||
            Number(preselectedLicense?.markup) ||
            '',
    )

    const [isRebate, setIsRebate] = useState(
        Number(preselectedLicense?.markup) === 0 || false,
    )
    const [priceAdjustmentError, setPriceAdjustmentError] = useState('')
    const [priceAdjustmentWarning, setPriceAdjustmentWarning] = useState('')

    const [percentagePerTier, setPercentagePerTier] = useState<string[]>(
        preselectedLicense?.margin_structure.percentage_per_tier.split(',') ||
            [],
    )
    const [isSearching, setIsSearching] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const { errorToast, successToast } = useToast()
    const inputRef = useRef<HTMLInputElement>(null)

    const paymentFrequencies = [
        { key: 'MONTHLY', value: 'Monthly' },
        { key: 'QUARTERLY', value: 'Quarterly' },
        { key: 'ANNUALLY', value: 'Annually' },
    ]

    const terms = [
        { key: 'MONTH', value: 'Month' },
        { key: 'QUARTER', value: 'Quarter' },
        { key: 'YEAR', value: 'Year' },
    ]

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
        const initialShowArticleSetDescriptions: { [key: number]: boolean } = {}

        selectedArticles.forEach((article) => {
            initialShowArticleSetDescriptions[article.article.id] =
                (article.description?.length || 0) > 0
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const parsedValue = Number(priceAdjustmentPercentage)

        if (isRebate) {
            if (parsedValue < 0 || parsedValue > 100) {
                setPriceAdjustmentError(
                    'Value needs to be a number between 0-100.',
                )
            }
        }

        setPriceAdjustmentWarning(
            parsedValue >= 30
                ? 'You are setting a unusually high value.' +
                      ' Please make sure this is correct.'
                : '',
        )
    }, [
        isRebate,
        priceAdjustmentPercentage,
        selectedInvoiceStartDate,
        selectedStartDate,
    ])

    useEffect(() => {
        let formDirty = true
        let selectedArticlesEdited = true
        let selectedSalesTiersEdited = true
        let selectedCustomerEdited = true
        let priceAdjustmentEdited = true

        if (preselectedLicense) {
            selectedArticlesEdited =
                selectedArticles.length !== preselectedLicense.articles.length

            selectedArticles.forEach((article) => {
                preselectedLicense.articles.forEach(
                    (preselectedArticle: any) => {
                        if (
                            article.article.id === preselectedArticle.article.id
                        ) {
                            if (
                                article.quantity !==
                                    preselectedArticle.quantity ||
                                article.description !==
                                    preselectedArticle.description
                            ) {
                                selectedArticlesEdited = true
                            }
                        }
                    },
                )
            })

            for (let i = 0; i < selectedSalesTiers.length; i += 1) {
                if (
                    selectedSalesTiers[i]?.vendor !==
                    preselectedLicense.sales_tiers[i]?.vendor
                ) {
                    selectedSalesTiersEdited = true
                    break
                }
                selectedSalesTiersEdited = false
            }

            selectedCustomerEdited =
                customers.find(
                    (customer) => customer.id === preselectedLicense.customer,
                )?.id !== selectedCustomer?.id

            if (isRebate) {
                priceAdjustmentEdited =
                    Number(priceAdjustmentPercentage) !==
                    Number(preselectedLicense.rebate)
            } else {
                priceAdjustmentEdited =
                    Number(priceAdjustmentPercentage) !==
                    Number(preselectedLicense.markup)
            }

            formDirty =
                preselectedLicense.name !== name ||
                preselectedLicense.license_key !== licenseKey ||
                selectedArticlesEdited ||
                selectedSalesTiersEdited ||
                selectedCustomerEdited ||
                priceAdjustmentEdited ||
                preselectedLicense.term !== selectedTerm ||
                preselectedLicense.payment_frequency !==
                    selectedPaymentFrequency ||
                preselectedLicense.start_date !== selectedStartDate ||
                preselectedLicense.sharlic_invoice_period_start_date !==
                    selectedInvoiceStartDate ||
                preselectedLicense.active !== active
        }

        const requiredFieldsSet =
            name !== '' &&
            selectedCustomer !== undefined &&
            licenseKey !== '' &&
            selectedArticles.length !== 0 &&
            selectedMarginStructure !== null &&
            selectedSalesTiers.length ===
                selectedMarginStructure.number_of_sales_tiers

        const hasErrors =
            salesTierError !== '' ||
            licenseKeyError !== '' ||
            articleError !== '' ||
            priceAdjustmentError !== '' ||
            Object.values(articleSetDescriptionErrors).some(
                (error) => error !== '',
            )

        setCanSubmit(requiredFieldsSet && !hasErrors && formDirty)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        name,
        selectedCustomer,
        licenseKey,
        selectedArticles,
        selectedMarginStructure,
        selectedSalesTiers,
        selectedStartDate,
        selectedTerm,
        selectedPaymentFrequency,
        priceAdjustmentPercentage,
        salesTierError,
        licenseKeyError,
        articleError,
        priceAdjustmentError,
        isRebate,
        selectedInvoiceStartDate,
        active,
        articleSetDescriptionErrors,
    ])

    useEffect(() => {
        if (!usePartnerArticles && vendor) {
            const tempFilteredArticles = articles.filter(
                (article) =>
                    !selectedArticles.some(
                        (selectedArticle) =>
                            article.id === selectedArticle.article.id,
                    ),
            )

            setArticleList(tempFilteredArticles)
            setMarginStructureList(marginStructures)
            setSelectedPartner(null)
        } else if (partners.length === 1) {
            setSelectedPartner(partners[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usePartnerArticles, vendor])

    useEffect(() => {
        if (selectedPartner && selectedPartner.id) {
            getPartnersArticles(selectedPartner.id).then((res) => {
                const data = res.data.filter((article: ArticleType) => {
                    return !selectedArticles.some((selectedArticle) => {
                        return article.id === selectedArticle.article.id
                    })
                })

                setArticleList(data)
            })

            getPartnersMarginStructures(selectedPartner.id).then((res) => {
                setMarginStructureList(res.data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPartner])

    useEffect(() => {
        const vendorSet = new Set(
            selectedSalesTiers.map((tier) => tier?.vendor?.id),
        )
        const hasDuplicates = vendorSet.size !== selectedSalesTiers.length
        if (hasDuplicates) {
            setSalesTierError(
                'Make sure none of the sales tiers have the same vendor.',
            )
            setCanSubmit(false)
        } else if (
            vendor &&
            selectedSalesTiers.length ===
                selectedMarginStructure?.number_of_sales_tiers &&
            selectedMarginStructure.number_of_sales_tiers > 1
        ) {
            let containsVendor = false

            selectedSalesTiers.forEach((tier?) => {
                if (tier?.vendor.id === vendor.id) {
                    containsVendor = true
                }
            })
            setSalesTierError('')

            if (!containsVendor) {
                setSalesTierError(
                    'You need to be part of the sales tiers in any level.',
                )
                setCanSubmit(false)
            }
        } else {
            setSalesTierError('')
        }
    }, [selectedMarginStructure, selectedSalesTiers, vendor])

    const handleSubmit = () => {
        if (!canSubmit || !selectedMarginStructure) {
            return
        }

        const articleSets = selectedArticles.map((article) => ({
            quantity: article.quantity,
            article: article.article.id,
            description: article.description,
        }))

        const licenseData = {
            name,
            customer_id: selectedCustomer?.id,
            rebate: isRebate ? Number(priceAdjustmentPercentage) : 0,
            markup: !isRebate ? Number(priceAdjustmentPercentage) : 0,
            license_key: licenseKey.toString(),
            articles: articleSets,
            margin_structure: selectedMarginStructure.id,
            sales_tiers: selectedSalesTiers,
            payment_frequency: selectedPaymentFrequency,
            term: selectedTerm,
            start_date: selectedStartDate,
            sharlic_invoice_period_start_date: selectedInvoiceStartDate,
            active,
        }

        const apiCall = preselectedLicense
            ? editLicense(licenseData, preselectedLicense.id)
            : addLicense(licenseData)

        apiCall
            .then(() => {
                onSubmit()

                successToast(
                    preselectedLicense
                        ? `License successfully edited.`
                        : `License successfully added.`,
                )
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    return setLicenseKeyError('License key already exists.')
                }
                if (err.response.status !== 500) {
                    return errorToast(
                        'Failed to create license, please try again.',
                    )
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const handleSetLicenseKey = (key: string) => {
        setLicenseKeyError('')
        setLicenseKey(key)
    }

    const handleSearch = (e: string) => {
        if (e !== '') {
            setIsSearching(true)

            const filteredList = articleList?.filter((article) =>
                article.name.toLowerCase().includes(e.toLowerCase()),
            )

            if (!filteredList) {
                setFilteredArticles(null)

                return
            }
            setFilteredArticles(filteredList)
        } else {
            setIsSearching(false)
            setFilteredArticles(null)
        }
    }

    const checkValidArticleCurrencies = (articleSetList: ArticleSetType[]) => {
        if (articleSetList.length === 0) {
            return
        }

        const { currency } = articleSetList[0].article

        const matchingCurrencies = articleSetList.every(
            (article) => article.article.currency === currency,
        )

        if (!matchingCurrencies) {
            setArticleError('All articles must have the same currency.')

            return
        }
        setArticleError('')
    }

    const handleAddArticle = (article: ArticleType) => {
        const tempSelectedArticleList = [
            ...selectedArticles,
            { quantity: 1, article },
        ]
        setSelectedArticles(tempSelectedArticleList)

        if (!articleList) {
            return
        }
        setArticleList(articleList.filter((item) => item !== article))

        checkValidArticleCurrencies(tempSelectedArticleList)
    }

    const handleRemoveArticle = (article: ArticleType) => {
        const tempSelectedArticleList = selectedArticles.filter(
            (item) => item.article.id !== article.id,
        )

        setSelectedArticles(tempSelectedArticleList)
        if (!articleList) {
            return
        }
        setArticleList([...articleList, article])

        checkValidArticleCurrencies(tempSelectedArticleList)
    }

    const handleUpdateArticleQuantity = (
        quantity: string,
        article: ArticleType,
    ) => {
        if (quantity === '0') {
            setArticleError(
                'Quantity must be greater than zero. Remove article instead.',
            )
        } else {
            setArticleError('')
        }

        const updatedArticles = selectedArticles.map((item) => {
            if (item.article.id === article.id) {
                return {
                    ...item,
                    quantity: parseInt(quantity, 10),
                }
            }

            return item
        })
        setSelectedArticles(updatedArticles)
    }

    const handleUpdateArticleDescription = (
        description: string,
        article: ArticleType,
    ) => {
        const errorMessage =
            description.length > 120
                ? 'Description must be less than 120 characters.'
                : ''

        setArticleSetDescriptionErrors(
            (prevState: { [key: string]: string }) => ({
                ...prevState,
                [article.id]: errorMessage,
            }),
        )

        if (errorMessage) {
            return
        }

        const updatedSelectedArticles = selectedArticles.map((item) =>
            item.article.id === article.id ? { ...item, description } : item,
        )
        setSelectedArticles(updatedSelectedArticles)
    }

    const handleSetSalesTiers = (order: number, id: string) => {
        if (!vendor) {
            return
        }

        const tempTierList = [...selectedSalesTiers]

        const indexToReplace = tempTierList.findIndex(
            (tier) => tier.order === order,
        )

        const selectedTierPartner = partners.find(
            (partner) => partner.id.toString() === id,
        )

        if (indexToReplace !== -1) {
            tempTierList[indexToReplace] = {
                order,
                vendor: selectedTierPartner || vendor,
            }
        } else {
            tempTierList.push({
                order,
                vendor: selectedTierPartner || vendor,
            })
        }

        setSelectedSalesTiers(tempTierList)
    }

    const handleSetSelectedPartner = (index: number) => {
        const partner = partners[index]

        if (selectedPartner === partner) {
            return
        }

        setSelectedPartner(partner)
        setSelectedSalesTiers([{ order: 1, vendor: partner }])
        setSelectedArticles([])
        setSelectedMarginStructure(null)
        setSelectedSalesTiers([])
    }

    const handleSetMarginStructure = (id: string) => {
        const filteredMarginStructure = marginStructureList.find(
            (marginStructure) => marginStructure.id === parseInt(id, 10),
        )

        setSelectedSalesTiers([])
        if (!filteredMarginStructure) {
            return
        }
        setSelectedMarginStructure(filteredMarginStructure)

        setPercentagePerTier(
            filteredMarginStructure.percentage_per_tier.split(','),
        )

        const vendorToUse =
            usePartnerArticles && selectedPartner ? selectedPartner : vendor

        const salesTiers = [
            { order: 1, vendor: vendorToUse },
            ...(filteredMarginStructure.number_of_sales_tiers === 2 &&
            vendor !== vendorToUse
                ? [{ order: 2, vendor }]
                : []),
        ] as SalesTierType[]

        setSelectedSalesTiers(salesTiers)
    }

    const renderPartnerDropdown = () => {
        if (!usePartnerArticles) {
            return null
        }

        if (partners.length === 1) {
            if (!selectedPartner) {
                setSelectedPartner(partners[0])
            }

            return (
                <>
                    <FormLabel>Partner</FormLabel>
                    <Form.Control
                        type="text"
                        placeholder={selectedPartner?.partner_name}
                        key={selectedPartner?.id}
                        disabled
                    />
                </>
            )
        }

        return (
            <DropdownContainer>
                <Dropdown>
                    <Dropdown.Toggle
                        id="partner-dropdown"
                        variant="outline-dark"
                    >
                        {(selectedPartner && selectedPartner.partner_name) ||
                            'Choose partner'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {partners.map((partner, index) => (
                            <Dropdown.Item
                                key={partner.id}
                                onClick={() => handleSetSelectedPartner(index)}
                            >
                                {partner.partner_name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </DropdownContainer>
        )
    }

    const renderErrorMessage = (message: string) => {
        if (!message) {
            return null
        }

        return <ErrorMessage>{message}</ErrorMessage>
    }

    const renderResponsiveTextInput = (article: ArticleSetType) => (
        <ResponsiveTextInput
            value={article.description?.toString() || ''}
            placeholder="Comment to show on invoice"
            onChange={(value) =>
                handleUpdateArticleDescription(value, article.article)
            }
            onBlur={(value) =>
                handleUpdateArticleDescription(value.trim(), article.article)
            }
        />
    )

    const renderArticleListItem = (article: ArticleSetType) => (
        <LicenseListItem key={article.article.id}>
            <section id="info-section">
                <span>{article.article.name}</span>
                <span>{`${localeNumber(Number(article.article.msrp))} ${
                    article.article.currency
                }`}</span>
                {renderResponsiveTextInput(article)}
            </section>
            <div>
                <NumberInputWrapper
                    value={article.quantity.toString()}
                    onChange={(e) =>
                        handleUpdateArticleQuantity(e, article.article)
                    }
                    config={{
                        min: 0,
                        max: 999,
                        allowEmpty: false,
                        allowLeadingZero: false,
                        allowDecimal: { isEnabled: false },
                    }}
                />
                <RemoveListItemButton
                    onClick={() => handleRemoveArticle(article.article)}
                    className="fas fa-times fa-lg"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter')
                            handleRemoveArticle(article.article)
                    }}
                    role="button"
                    tabIndex={0}
                    aria-label="Remove article"
                />
            </div>
        </LicenseListItem>
    )

    const renderSelectedArticles = () => (
        <ArticleList>
            {selectedArticles.map((article) => (
                <React.Fragment key={article.article.id}>
                    <ArticleSetWrapper>
                        {renderArticleListItem(article)}
                    </ArticleSetWrapper>
                    {renderErrorMessage(
                        articleSetDescriptionErrors[article.article.id],
                    )}
                </React.Fragment>
            ))}
        </ArticleList>
    )

    const renderSelectedArticlesSection = () => {
        const html =
            selectedArticles.length === 0 ? (
                <EmptyListIcon insideModal />
            ) : (
                renderSelectedArticles()
            )

        return (
            <>
                {html}
                {renderErrorMessage(articleError)}
            </>
        )
    }

    const renderLicenseTotalPrice = () => {
        if (selectedArticles.length === 0) {
            return null
        }

        let totalPrice = selectedArticles.reduce(
            (totalValue, article) =>
                totalValue + Number(article.article.msrp) * article.quantity,
            0,
        )

        if (isRebate) {
            totalPrice *= 1 - Number(priceAdjustmentPercentage) / 100
        } else {
            totalPrice *= 1 + Number(priceAdjustmentPercentage) / 100
        }

        return (
            <TotalPriceSection>
                <p>Total price:</p>
                <p>
                    {`${localeNumber(totalPrice)} ${
                        selectedArticles[0].article.currency
                    }`}
                </p>
            </TotalPriceSection>
        )
    }

    const renderMarginStructureDropdownItem = (
        marginStructure: MarginStructureType,
    ) => {
        return (
            <Dropdown.Item
                key={marginStructure.id}
                onClick={() =>
                    handleSetMarginStructure(marginStructure.id.toString())
                }
            >
                {marginStructure.name}
            </Dropdown.Item>
        )
    }

    const renderFilteredMarginStructures = () => {
        let availableMarginStructures = marginStructureList

        if (usePartnerArticles) {
            availableMarginStructures = availableMarginStructures.filter(
                (marginStructure) =>
                    marginStructure.number_of_sales_tiers !== 1,
            )
        }

        return availableMarginStructures.map((marginStructure) =>
            renderMarginStructureDropdownItem(marginStructure),
        )
    }

    const renderMissingAsset = (text: string) => {
        return <MissingAssetText>{text}</MissingAssetText>
    }

    const renderMarginStructureSection = () => {
        let html = null

        if (marginStructureList.length === 0) {
            html = !usePartnerArticles
                ? renderMissingAsset(
                      'Create a margin structure before adding a license.',
                  )
                : renderMissingAsset(
                      'No margin structures available for this partner.',
                  )
        } else {
            html = (
                <DropdownContainer>
                    <Dropdown>
                        <Dropdown.Toggle
                            disabled={usePartnerArticles && !selectedPartner}
                            variant="outline-dark"
                        >
                            {selectedMarginStructure?.name ||
                                'Choose margin structure'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {renderFilteredMarginStructures()}
                        </Dropdown.Menu>
                    </Dropdown>
                </DropdownContainer>
            )
        }

        return (
            <>
                <FormLabel>Margin Structure</FormLabel>
                {html}
            </>
        )
    }

    const getSalesTierText = (order: number) => {
        const tierVendor = selectedSalesTiers.find(
            (tier) => tier.order === order,
        )?.vendor

        if (!tierVendor) {
            if (
                selectedMarginStructure?.number_of_sales_tiers === 3 &&
                order === 2
            ) {
                return 'Choose distributor'
            }

            return 'Choose reseller'
        }

        if ('partner_name' in tierVendor) {
            return tierVendor.partner_name
        }

        return tierVendor.name
    }

    const renderPredeterminedSalesTier = (order: number) => {
        const tierVendor = selectedSalesTiers.find(
            (tier) => tier.order === order,
        )?.vendor

        if (!tierVendor) {
            return null
        }

        const tierName =
            'name' in tierVendor ? tierVendor.name : tierVendor.partner_name

        return (
            <Form.Control
                placeholder={tierName}
                type="text"
                key={`control-${order}`}
                disabled
            />
        )
    }

    const renderDropdownItems = (order: number) => {
        const vendors =
            usePartnerArticles && vendor ? [...partners, vendor] : partners

        return vendors.map((tierVendor) => (
            <Dropdown.Item
                onClick={() =>
                    handleSetSalesTiers(order, tierVendor.id.toString())
                }
                key={tierVendor.id}
            >
                {'name' in tierVendor
                    ? tierVendor.name
                    : tierVendor.partner_name}
            </Dropdown.Item>
        ))
    }

    const renderSalesTierDropdown = (order: number) => {
        return (
            <DropdownContainer key={`dropdown-${order}`}>
                <Dropdown>
                    <Dropdown.Toggle
                        id="sales-tier-dropdown"
                        variant="outline-dark"
                    >
                        {getSalesTierText(order)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>{renderDropdownItems(order)}</Dropdown.Menu>
                </Dropdown>
            </DropdownContainer>
        )
    }

    const renderSalesTiers = () => {
        if (!selectedMarginStructure) {
            return null
        }

        if (
            selectedMarginStructure.number_of_sales_tiers >
            partners.length + 1
        ) {
            return (
                <MissingAssetText>
                    Not enough partners to assign all sales tiers.
                </MissingAssetText>
            )
        }

        const salesTiers = []
        const numberOfSalesTiers = selectedMarginStructure.number_of_sales_tiers

        for (let order = 1; order <= numberOfSalesTiers; order += 1) {
            const isFirstTier = order === 1

            const isTwoTierWithPartnerArticles =
                order === 2 && numberOfSalesTiers === 2 && usePartnerArticles

            salesTiers.push(
                <h6 key={`tier-${order}`}>
                    Tier {order} ({percentagePerTier[order - 1]}%)
                </h6>,
            )

            if (isFirstTier || isTwoTierWithPartnerArticles) {
                salesTiers.push(renderPredeterminedSalesTier(order))
            } else {
                salesTiers.push(renderSalesTierDropdown(order))
            }
        }

        return salesTiers
    }

    const renderSalesTiersSection = () => {
        if (
            !selectedMarginStructure ||
            (usePartnerArticles && !selectedPartner)
        ) {
            return null
        }

        return (
            <>
                <FormLabel>Sales Tiers</FormLabel>
                {renderSalesTiers()}
                {renderErrorMessage(salesTierError)}
            </>
        )
    }

    const handleArticleRadioChange = () => {
        setUsePartnerArticles(!usePartnerArticles)
        setSelectedArticles([])
        setSelectedPartner(null)
        setSelectedMarginStructure(null)
        setArticleList(null)
        setSelectedSalesTiers([])
    }

    const renderArticleRadioButtons = () => {
        if (partners.length === 0) {
            return null
        }

        return (
            <>
                <FormLabel>Articles</FormLabel>
                <RadioButtons>
                    <Checkbox
                        {...{
                            type: 'radio',
                            id: 'my-articles',
                            name: 'article-options',
                            label: 'My articles',
                            defaultChecked: !usePartnerArticles,
                            onChange: () => handleArticleRadioChange(),
                        }}
                    />
                    <Checkbox
                        {...{
                            type: 'radio',
                            id: 'partner-articles',
                            name: 'article-options',
                            label: 'Partners articles',
                            defaultChecked: usePartnerArticles,
                            onChange: () => handleArticleRadioChange(),
                        }}
                    />
                </RadioButtons>
            </>
        )
    }

    const renderArticlesDropdownContainer = () => {
        return (
            <ArticlesDropdownContainer
                selectedArticles={selectedArticles}
                articleList={articleList}
                usePartnerArticles={usePartnerArticles}
                selectedPartner={selectedPartner}
                isSearching={isSearching}
                filteredArticles={filteredArticles}
                handleSearch={handleSearch}
                handleAddArticle={handleAddArticle}
            />
        )
    }

    const renderArticleSection = () => {
        return (
            <>
                {renderArticlesDropdownContainer()}
                {renderSelectedArticlesSection()}
            </>
        )
    }

    const renderNameField = () => {
        return (
            <>
                <FormLabel>Name</FormLabel>
                <Form.Control
                    {...{
                        ref: inputRef,
                        placeholder: 'License name',
                        value: name,
                        type: 'text',
                        onChange: (e: any) => setName(e.target.value),
                        onBlur: (e: any) => setName(e.target.value.trim()),
                    }}
                />
            </>
        )
    }

    const renderLicenseKeyField = () => {
        return (
            <>
                <FormLabel>License Key</FormLabel>
                <Form.Control
                    {...{
                        placeholder: 'License/Agreement identifier',
                        value: licenseKey,
                        type: 'text',
                        onChange: (e: any) =>
                            handleSetLicenseKey(e.target.value),
                        onBlur: (e: any) =>
                            handleSetLicenseKey(e.target.value.trim()),
                    }}
                />
                {renderErrorMessage(licenseKeyError)}
            </>
        )
    }

    const onCustomerSelected = (id: number) => {
        setSelectedCustomer(customers.find((obj) => obj.id === id))
    }

    const renderCustomerDropdown = () => {
        let html = null

        if (customers.length === 0) {
            html = renderMissingAsset(
                'Create a customer before adding a license.',
            )
        } else if (customers.length === 1) {
            if (!selectedCustomer) {
                setSelectedCustomer(customers[0])
            }

            html = (
                <Form.Control
                    placeholder={customers[0].name}
                    type="text"
                    key={customers[0].id}
                    disabled
                />
            )
        } else {
            html = (
                <DropdownContainer>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="outline-dark"
                            id="customer-toggle"
                        >
                            {selectedCustomer?.name || 'Choose customer'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {customers.map((customer) => (
                                <Dropdown.Item
                                    key={customer.id}
                                    onClick={() =>
                                        onCustomerSelected(customer.id)
                                    }
                                >
                                    {customer.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </DropdownContainer>
            )
        }

        return (
            <>
                <FormLabel>Customer</FormLabel>
                {html}
            </>
        )
    }

    const handleSetTerm = (term: string) => {
        const termIndex = terms.findIndex((item) => item.key === term)

        setSelectedTerm(term)
        setSelectedPaymentFrequency(paymentFrequencies[termIndex].key)
    }

    const renderTermDropdown = () => {
        const displayedTerm = terms.find((term) => term.key === selectedTerm)

        return (
            <>
                <FormLabel>Term</FormLabel>
                <DropdownContainer>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="outline-dark"
                            id="term-toggle"
                        >
                            {displayedTerm?.value}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {terms.map((term) => (
                                <Dropdown.Item
                                    key={term.key}
                                    onClick={() => {
                                        handleSetTerm(term.key)
                                    }}
                                >
                                    {term.value}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </DropdownContainer>
            </>
        )
    }

    const renderPaymentFrequencyDropdown = () => {
        const displayedTimePeriod = paymentFrequencies.find(
            (period) => period.key === selectedPaymentFrequency,
        )

        return (
            <>
                <FormLabel>Payment frequency</FormLabel>
                <DropdownContainer>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="outline-dark"
                            id="payment-frequency-toggle"
                        >
                            {displayedTimePeriod?.value}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {paymentFrequencies.map((period) => (
                                <Dropdown.Item
                                    key={period.key}
                                    onClick={() =>
                                        setSelectedPaymentFrequency(period.key)
                                    }
                                >
                                    {period.value}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </DropdownContainer>
            </>
        )
    }

    const validatePriceAdjustment = (value: string) => {
        const splitValue = value.split('.')
        if (splitValue[1]?.length > 2) {
            setPriceAdjustmentError('Max two decimals allowed.')
        } else if (value.endsWith('.')) {
            setPriceAdjustmentError('Do not end on a decimal point.')
        } else if (splitValue[0]?.length > 3) {
            setPriceAdjustmentError('Max three digits before decimal allowed.')
        } else {
            setPriceAdjustmentError('')
        }
    }

    const onPriceAdjustmentChanged = (value: string) => {
        validatePriceAdjustment(value)
        setPriceAdjustmentPercentage(value)
    }

    const handleSetStartDate = (date: string) => {
        if (date > selectedInvoiceStartDate) {
            setSelectedInvoiceStartDate(date)
        }
        setSelectedStartDate(date)
    }

    const handleSetInvoiceStartDate = (date: string) => {
        if (date < selectedStartDate) {
            setSelectedInvoiceStartDate(selectedStartDate)
        } else {
            setSelectedInvoiceStartDate(date)
        }
    }

    const renderStartDate = () => (
        <>
            <FormLabel>Start date</FormLabel>
            <Form.Control
                {...{
                    type: 'date',
                    value: selectedStartDate,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        handleSetStartDate(e.target.value),
                    'data-testid': 'start-date-input',
                }}
            />
        </>
    )

    const handlePriceAdjustmentRadioChange = () => {
        setIsRebate(!isRebate)
    }

    const renderPriceAdjustmentWarning = () => {
        if (!priceAdjustmentWarning || priceAdjustmentError) {
            return null
        }

        return <WarningMessage>{priceAdjustmentWarning}</WarningMessage>
    }

    const renderPriceAdjustmentRadioButtons = () => {
        return (
            <RadioButtons>
                <Checkbox
                    {...{
                        type: 'radio',
                        id: 'rebate',
                        name: 'rebate-markup-options',
                        label: 'Rebate',
                        defaultChecked: isRebate,
                        onChange: () => handlePriceAdjustmentRadioChange(),
                    }}
                />
                <Checkbox
                    {...{
                        type: 'radio',
                        id: 'markup',
                        name: 'rebate-markup-options',
                        label: 'Markup',
                        defaultChecked: !isRebate,
                        onChange: () => handlePriceAdjustmentRadioChange(),
                    }}
                />
            </RadioButtons>
        )
    }

    const renderPriceAdjustment = () => {
        return (
            <>
                <FormLabel>
                    Rebate/Markup (%)<span> - Optional</span>
                </FormLabel>
                <DoubleSection>
                    <div>
                        <PriceAdjustmentSection>
                            {renderPriceAdjustmentRadioButtons()}
                            <NumberInput
                                placeholder="-"
                                value={priceAdjustmentPercentage.toString()}
                                onChange={onPriceAdjustmentChanged}
                                config={{
                                    min: 0,
                                    max: 9999,
                                    allowLeadingZero: false,
                                    allowDecimal: { maxDecimalPlaces: 3 },
                                }}
                            />
                        </PriceAdjustmentSection>
                        {renderPriceAdjustmentWarning()}
                        {renderErrorMessage(priceAdjustmentError)}
                    </div>
                    <div>{renderLicenseTotalPrice()}</div>
                </DoubleSection>
            </>
        )
    }

    const renderInvoiceStartDate = () => {
        return (
            <>
                <FormLabel>Billed by Sharlic from</FormLabel>
                <Form.Control
                    {...{
                        type: 'date',
                        value: selectedInvoiceStartDate,
                        min: selectedStartDate,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSetInvoiceStartDate(e.target.value),
                    }}
                />
            </>
        )
    }

    const renderToggleActiveSwitch = () => {
        return (
            <Switch
                id="active-switch"
                checked={active}
                label="Activate license"
                onChange={() => setActive(!active)}
            />
        )
    }

    return (
        <Modal
            size="lg"
            onClose={onClose}
            title={preselectedLicense ? 'Edit' : 'New'}
            body={
                <ModalWrapper>
                    {renderToggleActiveSwitch()}
                    <DoubleSection>
                        <div>{renderNameField()}</div>
                        <div>{renderLicenseKeyField()}</div>
                    </DoubleSection>
                    {renderCustomerDropdown()}
                    <DoubleSection>
                        <div>{renderArticleRadioButtons()}</div>
                        <div>{renderPartnerDropdown()}</div>
                    </DoubleSection>
                    {renderArticleSection()}
                    {renderPriceAdjustment()}
                    {renderMarginStructureSection()}
                    {renderSalesTiersSection()}
                    {renderTermDropdown()}
                    <DoubleSection>
                        <div>{renderStartDate()}</div>
                        <div>{renderInvoiceStartDate()}</div>
                    </DoubleSection>
                    {renderPaymentFrequencyDropdown()}
                </ModalWrapper>
            }
            footer={
                <CancelSubmitFooter
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    canSubmit={canSubmit}
                />
            }
        />
    )
}
