export default function localeNumber(
    value: number,
    disableFractionDigits?: boolean,
) {
    const userLanguage = navigator.language || 'en-US'

    const formattedNumber = new Intl.NumberFormat(userLanguage, {
        style: 'decimal',
        minimumFractionDigits: disableFractionDigits ? 0 : 2,
        maximumFractionDigits: disableFractionDigits ? 0 : 2,
    }).format(value)

    return formattedNumber
}
