import React, { useEffect, useMemo, useState } from 'react'

import { getCustomersSubscriptions } from 'api/vendors'
import {
    DashboardCard,
    DashboardHeader,
    WorldMap,
    Coordinates,
    ActionsNeededCard,
} from 'apps/vendor/components/Cards'
import {
    CustomerSubscriptionDataType,
    LicenseType,
} from 'apps/vendor/interfaces/subscriptions'
import { LicenseRevenueData } from 'apps/vendor/utils'
import useVendor from 'hooks/useVendor'

import {
    DashboardCardsRow,
    DashboardWrapper,
    ActionsAndMapWrapper,
    DashboardPageWrapper,
} from './Dashboard.styled'

export default function DashboardPage() {
    const [customersSubscriptionsData, setCustomersSubscriptionsData] =
        useState<CustomerSubscriptionDataType[] | null>(null)
    const [loading, setLoading] = useState(true)
    const { vendor } = useVendor()

    const activeLicenses = useMemo(() => {
        return (
            customersSubscriptionsData?.reduce(
                (licenses: LicenseType[], customer) => {
                    const active = customer.licenses.filter(
                        (license) => license.active,
                    )

                    return licenses.concat(active)
                },
                [],
            ) || []
        )
    }, [customersSubscriptionsData])

    const [firstLicense] =
        activeLicenses.filter((license) => license.active) || []
    const [firstArticle] = firstLicense ? firstLicense.articles : []
    const selectedCurrency = firstArticle?.article.currency

    const dashboardLicenseData = useMemo(
        () =>
            LicenseRevenueData({
                licenses: activeLicenses,
                vendorId: vendor?.id || null,
                currency: selectedCurrency,
            }),
        [activeLicenses, selectedCurrency, vendor?.id],
    )

    const updateCustomerSubscriptions = () => {
        setLoading(true)

        getCustomersSubscriptions()
            .then((response) => {
                setCustomersSubscriptionsData(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updateCustomerSubscriptions()
    }, [])

    if (!vendor || loading) return null

    const annualRecurringRevenueCard = () => {
        return (
            <DashboardCard
                image="fa-solid fa-chart-mixed"
                title="ARR"
                value={dashboardLicenseData?.totalRevenue || '---'}
                description="Estimated annual recurring revenue."
            />
        )
    }

    const licenseCountCard = () => {
        return (
            <DashboardCard
                image="fa-regular fa-credit-card"
                title="Active Licenses"
                value={activeLicenses?.length.toString()}
                description="Total number of active licenses."
            />
        )
    }

    const favoritePartnersCard = () => {
        return (
            <DashboardCard
                image="fa-solid fa-handshake"
                title="Favorite Partners"
                value="---"
                description="Under construction"
            />
        )
    }

    const actionsNeededCard = () => {
        return (
            <ActionsNeededCard value="---" description="Under construction" />
        )
    }

    const renderDashboardCards = () => {
        return (
            <DashboardCardsRow>
                {annualRecurringRevenueCard()}
                {licenseCountCard()}
                {favoritePartnersCard()}
            </DashboardCardsRow>
        )
    }

    const renderDashboardMap = () => {
        if (!vendor) {
            return null
        }
        const coordinates = [42.751523, -86.095034] as Coordinates

        // const vendorAddress = {
        //     name: vendor.name,
        //     address: vendor.address_line_1,
        //     city: vendor.city,
        // }
        // Googles geocoding will be implemented in backend,
        // for now we will use static coordinates.
        // Change coordinates for demo purposes.

        return (
            <ActionsAndMapWrapper>
                {actionsNeededCard()}
                <WorldMap coordinates={coordinates} />
            </ActionsAndMapWrapper>
        )
    }

    return (
        <DashboardPageWrapper>
            <DashboardHeader />
            <DashboardWrapper>
                {renderDashboardCards()}
                {renderDashboardMap()}
            </DashboardWrapper>
        </DashboardPageWrapper>
    )
}
