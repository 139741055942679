import React, { useEffect, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'

import { ArticleType } from 'apps/vendor/pages/Articles'

import { ArticleSetType } from '../Modals/LicenseModal'
import { DropdownContainer } from '../Modals/Modals.styled'
import { PartnerType } from '../PartnerList'
import {
    ArticleCurrencyDropdownContainer,
    CurrencyDropdown,
} from './ArticlesDropdownContainer.styled'

type ArticlesDropdownProps = {
    selectedArticles: ArticleSetType[]
    articleList: ArticleType[] | null
    filteredArticles: ArticleType[] | null
    selectedPartner: PartnerType | null
    usePartnerArticles: boolean
    isSearching: boolean
    handleSearch: (e: string) => void
    handleAddArticle: (article: ArticleType) => void
}

export default function ArticlesDropdownContainer(
    defaultProps: ArticlesDropdownProps,
) {
    const {
        selectedArticles,
        articleList,
        filteredArticles,
        selectedPartner,
        usePartnerArticles,
        isSearching,
        handleSearch,
        handleAddArticle,
    } = defaultProps

    const [selectedCurrency, setSelectedCurrency] = useState<string | null>(
        null,
    )

    const dropdownArticles = isSearching ? filteredArticles : articleList

    const dropdownArticlesCurrencies = dropdownArticles
        ? dropdownArticles.map(
              (article: { currency: string }) => article.currency,
          )
        : []

    const uniqueCurrencies: string[] = Array.from(
        new Set(dropdownArticlesCurrencies),
    )

    const filteredDropdownArticles =
        dropdownArticles?.filter(
            (article: { currency: string }) =>
                article.currency === selectedCurrency,
        ) || []

    const articlesAvailable = filteredDropdownArticles.length > 0
    const [className, setClassName] = useState('')
    const [text, setText] = useState('Add article')

    useEffect(() => {
        if (
            (!usePartnerArticles || (usePartnerArticles && selectedPartner)) &&
            !articlesAvailable &&
            !selectedArticles.length &&
            !isSearching
        ) {
            setClassName('missing-asset')

            setText(
                !usePartnerArticles
                    ? 'Create an article before adding a license.'
                    : 'No articles available for this partner.',
            )
        } else {
            setClassName('')
            setText('Add article')
        }
    }, [
        usePartnerArticles,
        selectedPartner,
        articlesAvailable,
        selectedArticles,
        isSearching,
    ])

    useEffect(() => {
        if (selectedArticles.length > 0) {
            setSelectedCurrency(selectedArticles[0].article.currency)

            return
        }
        if (!selectedCurrency && uniqueCurrencies.length === 0) {
            setSelectedCurrency('SEK')

            return
        }
        if (
            uniqueCurrencies.length === 1 &&
            selectedCurrency !== uniqueCurrencies[0]
        ) {
            setSelectedCurrency(uniqueCurrencies[0])
        }
    }, [
        selectedArticles,
        selectedCurrency,
        uniqueCurrencies,
        usePartnerArticles,
    ])

    const renderCurrencyDropdown = () => {
        return (
            <CurrencyDropdown>
                <Dropdown.Toggle
                    id="currency-dropdown"
                    variant="outline-dark"
                    disabled={
                        uniqueCurrencies.length <= 1 ||
                        selectedArticles.length > 0
                    }
                >
                    {selectedCurrency}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {uniqueCurrencies.map((currency) => (
                        <Dropdown.Item
                            key={currency}
                            onClick={() => setSelectedCurrency(currency)}
                        >
                            {currency}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </CurrencyDropdown>
        )
    }

    const renderArticlesDropdown = () => {
        return (
            <DropdownContainer>
                <Dropdown>
                    <Dropdown.Toggle
                        id="articles-dropdown"
                        variant="outline-dark"
                        className={className}
                        disabled={
                            className !== '' ||
                            (usePartnerArticles && !selectedPartner)
                        }
                    >
                        {text}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Form.Control
                            {...{
                                type: 'text',
                                placeholder: 'Search...',
                                onChange: (e: any) =>
                                    handleSearch(e.target.value),
                                onBlur: (e: any) => {
                                    e.target.value = ''
                                    handleSearch('')
                                },
                            }}
                        />
                        {articlesAvailable ? (
                            filteredDropdownArticles.map((article) => (
                                <Dropdown.Item
                                    key={article.id}
                                    onClick={() => handleAddArticle(article)}
                                >
                                    {article.name}
                                </Dropdown.Item>
                            ))
                        ) : (
                            <Dropdown.Item disabled>
                                No matching articles
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </DropdownContainer>
        )
    }

    return (
        <ArticleCurrencyDropdownContainer>
            {renderCurrencyDropdown()}
            {renderArticlesDropdown()}
        </ArticleCurrencyDropdownContainer>
    )
}
