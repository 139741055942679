import styled from 'styled-components'
import { secondary } from 'utils/colors'

export const DropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
    margin-bottom: 0.5rem;

    .dropdown-menu {
        width: 100%;
        padding-top: 0;
    }

    .dropdown-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        border: 1px solid ${secondary.lightGray};
        width: 100%;

        :hover,
        .show {
            background-color: ${secondary.lightGray};
            color: black;
        }
    }
`
