import { Tabs } from 'react-bootstrap'

import styled from 'styled-components'
import { primary } from 'utils/colors'

export const TabWrapper = styled(Tabs)`
    padding-left: 10px;
    > * {
        font-size: 1.4rem;

        > .nav-link {
            color: ${primary.gray};
        }

        > .nav-link.active {
            color: ${primary.darkBlue};
        }
    }
`
