import React from 'react'

import BootstrapTable from 'apps/shared/components/BootstrapTable'
import { EditButton } from 'apps/shared/components/Buttons'
import { LicenseType } from 'apps/vendor/interfaces/subscriptions'
import { localeNumber } from 'apps/vendor/utils'

import { ArticleSetType } from '../../Modals/LicenseModal'
import { TableWrapper } from '../Tables.styled'

export default function LicensesTable(props: {
    license: LicenseType
    setSelectedLicense: (license: LicenseType) => void
}): JSX.Element {
    const { license, setSelectedLicense } = props

    const getHeaders = () => {
        return ['Article', 'Quantity', 'Unit MSRP', 'Total MSRP', 'Currency']
    }

    const getData = () => {
        return license.articles.map((articleSet: ArticleSetType) => ({
            article: {
                name: articleSet.article.name,
                msrp: articleSet.article.msrp,
                currency: articleSet.article.currency,
            },
            quantity: articleSet.quantity,
            unitMsrp: localeNumber(Number(articleSet.article.msrp)),
            totalArticleMSRP: localeNumber(
                articleSet.quantity * Number(articleSet.article.msrp),
            ),
        }))
    }

    const getAccessors = () => {
        return [
            'article.name',
            'quantity',
            'unitMsrp',
            'totalArticleMSRP',
            'article.currency',
        ]
    }

    return (
        <TableWrapper numberOfColumns={5}>
            <BootstrapTable
                striped
                headers={getHeaders()}
                data={getData()}
                accessors={getAccessors()}
            />
            <EditButton onClick={() => setSelectedLicense(license)} />
        </TableWrapper>
    )
}
