import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import getSupportedCountries from 'api/countries/getCountries'
import { validateToken } from 'api/tokens'
import { registerVendor } from 'api/vendors'
import { SharlicLogo, SharlicWhiteLogo } from 'apps/shared/components/Logo'
import { SharlicBubbles } from 'assets'
import useToast from 'hooks/useToast'
import { tokenFormats, whitelistURLParams } from 'routes/utils'

import { Grid, Space, RegisterForm, Image } from './Register.styled'

export default function RegisterVendorPage() {
    const location = useLocation()

    const [email, setEmail] = useState(
        location.search.split('email=')[1]?.split('/')[0] || '',
    )
    const [password, setPassword] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [selectedCountry, setSelectedCountry] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [nextParam, setNextParam] = useState('')
    const navigate = useNavigate()
    const { invitationID } = useParams()
    const [countryChoices, setCountryChoices] = useState([])
    const { errorToast } = useToast()
    const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null)

    useEffect(() => {
        validateToken(invitationID)
            .then(() => {
                setTokenIsValid(true)
            })
            .catch(() => {
                setTokenIsValid(false)
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault()

        if (!invitationID?.match(tokenFormats.UUID)) {
            errorToast(
                // eslint-disable-next-line max-len
                'There was a problem with your invitation, please try again. If the problem persists, contact Sharlic.',
            )

            return
        }

        const params = {
            email,
            password,
            company_name: companyName,
            first_name: firstName,
            last_name: lastName,
            token: invitationID,
            country: selectedCountry,
        }

        registerVendor(params)
            .then(() => {
                if (nextParam) {
                    navigate(`${nextParam}`)
                } else {
                    navigate('/')
                }
            })
            .catch((error) => {
                if (
                    error.response.status === 400 &&
                    error.response.data.detail.includes('Password')
                ) {
                    errorToast(
                        `Your password has to be at least 10 characters.
                        It must contain at least one letter and number.`,
                    )
                } else {
                    errorToast(
                        `There was a problem with your registration,
                        please try again.
                        If the problem persists, contact Sharlic.`,
                    )
                }
            })
    }

    useEffect(() => {
        const queryParam = location.search
        const params = queryParam.split('=')
        if (params[0] === '?next') {
            if (params[1].match(whitelistURLParams.PARTNER_INVITATION)) {
                setNextParam(params[1])
            }
        }

        getSupportedCountries().then((res) =>
            setCountryChoices(res.data.countries),
        )
    }, [location.search])

    function renderCountrySelect(): ReactNode {
        const countryOptions: Array<ReactNode> = []

        countryChoices?.sort((a, b) => (a[1] > b[1] ? 1 : -1))

        countryChoices?.forEach((country) => {
            countryOptions.push(
                <option value={country[0]} key={country[0]}>
                    {country[1]}
                </option>,
            )
        })

        return (
            <select
                id="country"
                defaultValue=""
                required
                onChange={(e) => setSelectedCountry(e.target.value)}
            >
                <option value="" disabled hidden>
                    Country
                </option>
                {countryOptions}
            </select>
        )
    }

    if (tokenIsValid === null) {
        return null
    }

    return tokenIsValid ? (
        <Grid>
            <Image>
                <SharlicWhiteLogo />
                <SharlicLogo />
                <img src={SharlicBubbles} alt="bubbles" id="bubbles" />
            </Image>
            <RegisterForm>
                <Space />
                <div>
                    <h1>Create an account</h1>
                </div>
                <div />
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        required
                        id="companyName"
                        placeholder="Company Name"
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                    {renderCountrySelect()}
                    <input
                        type="text"
                        required
                        id="firstName"
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input
                        type="text"
                        required
                        id="lastName"
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <input
                        type="email"
                        value={email}
                        disabled={!!email}
                        required
                        id="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        required
                        id="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div />
                    <button
                        className="rounded"
                        type="submit"
                        id="submit-button"
                    >
                        Create account
                    </button>
                </form>
                <p id="footer">
                    Already have an account? <a href="/">Log in</a>
                </p>
                <Space />
            </RegisterForm>
        </Grid>
    ) : (
        <h1>
            Your token has expired, contact your partner for a new invitation.
            <br />
            Contact sharlic if problem persists.
        </h1>
    )
}
