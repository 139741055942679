import React from 'react'

import Modal from 'apps/shared/components/Modals/Modal'

import { CancelSubmitFooter } from '../Footers'

export interface ConfirmModalProps {
    body: JSX.Element | JSX.Element[]
    onClose: () => void
    onSubmit: () => void
    customSubmitText?: string
}

export default function ConfirmModal(defaultProps: ConfirmModalProps) {
    const { onClose, onSubmit, body, customSubmitText } = defaultProps

    return (
        <Modal
            onClose={onClose}
            title="Are you sure?"
            body={body}
            footer={
                <CancelSubmitFooter
                    onClose={onClose}
                    onSubmit={onSubmit}
                    customSubmitText={customSubmitText}
                />
            }
        />
    )
}
