import React, { useEffect, useState } from 'react'

import { getPartners } from 'api/partners'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { DeleteButton } from 'apps/shared/components/Buttons'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import PageHeader from 'apps/vendor/pages/PageHeader'
import {
    AccordionBody,
    AccordionWrapper,
    BadgeRow,
    HeaderRow,
} from 'apps/vendor/pages/pages.styled'
import { formatDate } from 'apps/vendor/utils'
import useToast from 'hooks/useToast'

import { DeletePartnerModal } from '../Modals'

export type PartnerType = {
    id: number
    partner_name: string
    contact_email: string
    created_at: string
}

interface partnerType {
    id: number
    name: string
}

export default function PartnerList(props: {
    updatePartners: (data: PartnerType[]) => void
    partners: PartnerType[]
}) {
    const { updatePartners, partners } = props
    const [selectedVendor, setSelectedVendor] = useState({} as partnerType)

    const [partnerObjectsList, setPartnerObjectsList] = useState<
        PartnerType[] | null
    >(null)
    const { errorToast } = useToast()

    const [
        showDeletePartnerConfirmationModal,
        setShowDeletePartnerConfirmationModal,
    ] = useState(false)

    const handlePartnerDelete = (id: number, name: string) => {
        setSelectedVendor({ id, name })
        setShowDeletePartnerConfirmationModal(true)
    }

    const onDeletePartnerModalClose = () => {
        setShowDeletePartnerConfirmationModal(false)
    }

    const onDeletePartner = () => {
        errorToast(`Could not remove ${selectedVendor.name} as a partner`)
        setShowDeletePartnerConfirmationModal(false)
    }

    useEffect(() => {
        getPartners().then((response) => {
            updatePartners(response.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!partners) return

        setPartnerObjectsList([
            ...partners.map((partner, index) => {
                return {
                    ...partner,
                    buttons: (
                        <DeleteButton
                            onClick={() =>
                                handlePartnerDelete(index, partner.partner_name)
                            }
                        />
                    ),
                }
            }),
        ])
    }, [partners])

    const getPartnerBody = (partner: PartnerType) => {
        const date = formatDate(new Date(partner.created_at))

        return (
            <AccordionBody>
                <div>
                    <p>Partner since</p>
                    <p>{date}</p>
                </div>
            </AccordionBody>
        )
    }

    const renderTable = () => {
        if (!partnerObjectsList) return null

        if (partnerObjectsList.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                <HeaderRow numberOfColumns={2} style={{ marginTop: '1.5rem' }}>
                    <span>Name</span>
                    <span>Contact Email</span>
                </HeaderRow>

                <AccordionWrapper alwaysOpen>
                    {partnerObjectsList.map((partner, index) => {
                        return (
                            <AccordionItem
                                key={partner.partner_name}
                                eventKey={index.toString()}
                                header={
                                    <BadgeRow numberOfColumns={2}>
                                        <span>{partner.partner_name}</span>
                                        <span>{partner.contact_email}</span>
                                    </BadgeRow>
                                }
                                body={getPartnerBody(partner)}
                            />
                        )
                    })}
                </AccordionWrapper>
            </>
        )
    }

    return (
        <>
            <PageHeader />
            {showDeletePartnerConfirmationModal && (
                <DeletePartnerModal
                    onClose={onDeletePartnerModalClose}
                    onSubmit={onDeletePartner}
                    vendor={selectedVendor}
                />
            )}
            {renderTable()}
        </>
    )
}
