import { Vendor } from 'state/vendor/VendorState'

import { ArticleSetType } from '../components/Modals/LicenseModal'
import { PartnerType } from '../components/PartnerList/PartnerList'

export enum Term {
    MONTH = 'MONTH',
    QUARTER = 'QUARTER',
    YEAR = 'YEAR',
}

export enum TimePeriod {
    MONTHLY = 'Monthly',
    QUARTERLY = 'Quarterly',
    ANNUALY = 'Annually',
}

export enum CountryCode {
    SE = 'SE',
    US = 'US',
}

export enum Language {
    SWEDISH = 'sv-SE',
    ENGLISH = 'en-US',
}

export enum Currency {
    SEK = 'SEK',
    USD = 'USD',
    EUR = 'EUR',
}

export interface RevenueDistributionType {
    vendor: string
    vendor_share: number
    sharlic_fee: number
    share_pre_fee: number
    share_percentage: number
}

export interface LicenseType {
    id: number
    customer: number
    name: string
    license_key: string | number
    vendor: string
    articles: [ArticleSetType]
    rebate: number
    markup: number
    payment_frequency: TimePeriod
    term: Term
    start_date: string
    sharlic_invoice_period_start_date: string
    next_invoice_due_date?: string
    last_invoice_due_date?: string
    sales_tiers: SalesTierType[]
    margin_structure: MarginStructureType
    total_msrp: number
    total_price: number
    revenue_distribution?: RevenueDistributionType[]
    active: boolean
}

export interface CustomerType {
    id: number
    name: string
    external_customer_id: string
    reference: string
    contact_email: string
    description: string
    country: CountryCode
    address_line_1: string
    address_line_2: string
    zip_code: string
    city: string
    state: string
    phone_number: string
    language: Language
    currency: Currency
    created_at: string
    taxable: boolean
}

export interface SharlicFixedFeeType {
    amount: number
    currency: string
}

export interface MarginStructureType {
    id: number
    name: string
    description: string
    number_of_sales_tiers: number
    percentage_per_tier: string
    sharlic_share_percentage: number
    sharlic_fixed_fee: SharlicFixedFeeType
    available_for_partners: boolean
    is_default: boolean
    created_at: string
}

export interface SalesTierType {
    order: number
    vendor: Vendor | PartnerType
}

export interface CustomerSubscriptionDataType {
    id: number
    name: string
    licenses: LicenseType[]
}

export interface SubscriptionAPIType {
    data: CustomerSubscriptionDataType[]
    status: number
    statusText: string
}

export interface GeneratedHeader {
    id: number
    customer: CustomerType
    arr: number
    nextInvoice: string
    invoiceValue: string
    valueSinceStart: number
}

export interface GeneratedArticle {
    id: number
    licenseKey: string
    article: string
    quantity: number
    price: number
    paymentFreq: number
}

export interface GeneratedSubscriptionList {
    header: GeneratedHeader
    articles: GeneratedArticle[]
}
