import { Container as BContainer, Form, InputGroup } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const Container = styled(BContainer)`
    max-width: 600px;
    margin-left: 0%;

    .stripe_button {
        width: 16px;
    }
`

export const FormLabel = styled(Form.Label)`
    font-size: 0.9rem;

    > span {
        font-weight: normal;
        color: ${primary.gray};
    }
`

export const EditButton = styled(InputGroup.Text)`
    color: ${primary.darkGray};
    cursor: pointer;
`

export const StripeEditableField = styled(Form.Control)`
    color: ${primary.darkGray};
`

export const LogoInput = styled.div`
    display: flex;
    width: 100%;

    > button {
        margin-left: 10px;
        height: 100%;
    }
`

export const LogoArea = styled.div`
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${secondary.lightGray};
    border-radius: 5px;

    > img {
        padding: 2px;
        pointer-events: none;
        width: fit-content;
        max-width: 150px;
        max-height: 150px;
        height: auto;
        margin: 8px;
    }
`

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`

export const AddressArea = styled(Form.Control)`
    resize: none;
    height: 8rem;
    width: 100%;
`

export const SettingsFormGroup = styled(Form.Group)`
    margin-bottom: 2rem;

    .form-control:not(:last-child) {
        margin-bottom: 8px;
    }

    #submit-button {
        margin-top: 0.8rem;
    }
`
