import React, { useEffect, useState } from 'react'

import { getArticles } from 'api/articles'
import { getCustomers } from 'api/customers'
import { getMarginStructures } from 'api/marginStructures'
import { getPartners } from 'api/partners'
import { getCustomersSubscriptions } from 'api/vendors'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import CustomerSubscriptionInfo from 'apps/vendor/components/CustomerSubscriptionsInfo'
import { LicenseModal } from 'apps/vendor/components/Modals'
import { PartnerType } from 'apps/vendor/components/PartnerList'
import { CustomerSubscriptionsTable } from 'apps/vendor/components/Tables'
import {
    CustomerSubscriptionDataType,
    CustomerType,
    LicenseType,
    MarginStructureType,
} from 'apps/vendor/interfaces/subscriptions'
import useVendor from 'hooks/useVendor'

import { ArticleType } from '../Articles'
import PageHeader from '../PageHeader'
import { AccordionWrapper, BadgeRow, HeaderRow } from '../pages.styled'

export default function Subscriptions() {
    const [customersSubscriptionsData, setCustomersSubscriptionsData] =
        useState<CustomerSubscriptionDataType[] | null>(null)
    const [partners, setPartners] = useState<PartnerType[] | null>(null)

    const [marginStructures, setMarginStructures] = useState<
        MarginStructureType[] | null
    >(null)
    const [customers, setCustomers] = useState<CustomerType[] | null>(null)
    const [articles, setArticles] = useState<ArticleType[] | null>(null)
    const [showLicenseModal, setShowLicenseModal] = useState(false)

    const [selectedLicense, setSelectedLicense] = useState<LicenseType | null>(
        null,
    )
    const { vendor } = useVendor()

    useEffect(() => {
        Promise.all([
            getMarginStructures(),
            getPartners(),
            getCustomers(),
            getArticles(),
            getCustomersSubscriptions(),
        ]).then(
            ([
                marginStructuresRes,
                partnersRes,
                customersRes,
                articlesRes,
                customerSubscriptionsRes,
            ]) => {
                setMarginStructures(marginStructuresRes.data)
                setPartners(partnersRes.data)
                setCustomers(customersRes.data)
                setArticles(articlesRes.data)
                setCustomersSubscriptionsData(customerSubscriptionsRes.data)
            },
        )
    }, [])

    const updateCustomerSubscriptions = () => {
        getCustomersSubscriptions().then((resp) => {
            setCustomersSubscriptionsData(resp.data)
        })
    }

    const onLicenseModalSubmit = () => {
        updateCustomerSubscriptions()
        setSelectedLicense(null)
        setShowLicenseModal(false)
    }

    const handleSetSelectedLicense = (license: LicenseType) => {
        setSelectedLicense(license)
        setShowLicenseModal(!showLicenseModal)
    }

    if (!customersSubscriptionsData) return null

    if (customersSubscriptionsData.length === 0) return <EmptyListIcon />
    if (!partners || !marginStructures || !customers || !articles || !vendor)
        return null

    return (
        <div>
            <PageHeader />
            {showLicenseModal && (
                <LicenseModal
                    onClose={() => {
                        setShowLicenseModal(!showLicenseModal)
                        setSelectedLicense(null)
                    }}
                    onSubmit={() => onLicenseModalSubmit()}
                    articles={articles}
                    marginStructures={marginStructures}
                    partners={partners}
                    preselectedLicense={selectedLicense}
                    customers={customers}
                />
            )}
            <HeaderRow numberOfColumns={5} rightJustifiedColumns={[2, 3, 4, 5]}>
                <span>Customer</span>
                <span>ARR</span>
                <span>Next Invoice</span>
                <span>Invoice Value</span>
                <span>Value Since Start</span>
            </HeaderRow>

            <AccordionWrapper alwaysOpen>
                {customersSubscriptionsData?.map((customerData, index) => {
                    return (
                        <AccordionItem
                            key={customerData.id}
                            eventKey={index.toString()}
                            header={
                                <BadgeRow
                                    numberOfColumns={5}
                                    rightJustifiedColumns={[2, 3, 4, 5]}
                                >
                                    <CustomerSubscriptionInfo
                                        customerData={customerData}
                                    />
                                </BadgeRow>
                            }
                            body={
                                <CustomerSubscriptionsTable
                                    customerSubscriptions={
                                        customerData.licenses
                                    }
                                    setSelectedLicense={
                                        handleSetSelectedLicense
                                    }
                                />
                            }
                        />
                    )
                })}
            </AccordionWrapper>
        </div>
    )
}
