/* eslint-disable no-param-reassign, max-len */
import { createSlice } from '@reduxjs/toolkit'
import LoadingStatus from 'state/enums/LoadingStatus'

import { loadUser } from './actions'
import UserState from './UserState'

const initialState = {
    isLoggedIn: false,
    data: undefined,
    status: LoadingStatus.IDLE,
    error: undefined,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser: (state: UserState) => {
            state.status = LoadingStatus.IDLE
            state.isLoggedIn = true
        },
        logoutUser: (state: UserState) => {
            state.status = LoadingStatus.LOADED

            state.data = {
                id: 0,
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                user_type: '',
                permissions: [],
                date_joined: '',
            }

            state.isLoggedIn = false
        },
        loadUser: (state: UserState) => {
            state.status = LoadingStatus.IDLE
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadUser.pending, (state: UserState) => {
                state.status = LoadingStatus.LOADING
            })
            .addCase(loadUser.fulfilled, (state: UserState, action) => {
                state.status = LoadingStatus.LOADED

                const { payload } = action

                state.data = {
                    id: payload.data.id,
                    first_name: payload.data.first_name,
                    last_name: payload.data.last_name,
                    email: payload.data.email,
                    phone_number: payload.data.phone_number,
                    user_type: payload.data.user_type,
                    permissions: [
                        payload.data.user_type,
                        payload.data.is_vendor_admin && 'is_vendor_admin',
                    ],
                    date_joined: payload.data.date_joined,
                }

                state.isLoggedIn = true
            })
            .addCase(loadUser.rejected, (state: UserState, action) => {
                state.status = LoadingStatus.FAILED
                state.error = action.error.message
                state.isLoggedIn = false
            })
    },
})

export default userSlice
