import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const MapMarker = styled.div`
    color: ${primary.darkBlue};
    border-radius: 50%;
    font-size: 1.5em;
`
export const MapContainerWrapper = styled.div`
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 1px;
    border: 1px solid ${secondary.whisperWhite};
    background: ${secondary.almostWhiteGray};

    @media screen and (max-width: 768px) {
        display: none;
    }
`
