import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import { MapContainerWrapper, MapMarker } from './WorldMap.styled'

export type Coordinates = [number, number]

interface WorldMapProps {
    coordinates: Coordinates
    vendorAddress?: {
        name: string
        address?: string
        city?: string
    }
}

export default function WorldMap(defaultProps: WorldMapProps) {
    const { coordinates, vendorAddress } = defaultProps

    const baseMapUrl =
        // eslint-disable-next-line max-len
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
    const zoom = 7
    const [customIcon, setCustomIcon] = useState<L.DivIcon | null>(null)

    useEffect(() => {
        const container = document.createElement('div')
        const root = createRoot(container)
        root.render(<MapMarker className="fas fa-map-marker-alt" />)

        const icon = L.divIcon({
            className: 'Marker',
            html: container,
            iconAnchor: [7, 0],
        })

        setCustomIcon(icon)
    }, [])

    const renderPopup = () => {
        if (!vendorAddress) return null

        return (
            <Popup>
                <div>
                    <h3>{vendorAddress?.name}</h3>
                    <p>{vendorAddress?.address}</p>
                    <p>{vendorAddress?.city}</p>
                </div>
            </Popup>
        )
    }

    const renderMapContainer = () => {
        return (
            <MapContainer
                center={coordinates}
                zoom={zoom}
                style={{ height: '100%', width: '100%', borderRadius: '10px' }}
                worldCopyJump
                minZoom={2}
            >
                <TileLayer url={baseMapUrl} attribution="" />
                {customIcon && (
                    <Marker position={coordinates} icon={customIcon}>
                        {renderPopup()}
                    </Marker>
                )}
            </MapContainer>
        )
    }

    return <MapContainerWrapper>{renderMapContainer()}</MapContainerWrapper>
}
