import React from 'react'

import { CustomerSubscriptionDataType } from 'apps/vendor/interfaces/subscriptions'
import { formatDate, LicenseRevenueData } from 'apps/vendor/utils'
import useVendor from 'hooks/useVendor'

interface CustomerSubscriptionsInfoProps {
    customerData: CustomerSubscriptionDataType
}

export default function CustomerSubscriptionsInfo(
    defaultProps: CustomerSubscriptionsInfoProps,
) {
    const { customerData } = defaultProps
    const { vendor } = useVendor()

    const activeLicenses =
        customerData.licenses.filter((license) => license.active) || []

    const [firstLicense] =
        activeLicenses.filter((license) => license.active) || []
    const [firstArticle] = firstLicense ? firstLicense.articles : []
    const selectedCurrency = firstArticle?.article.currency

    const licenses = LicenseRevenueData({
        licenses: activeLicenses,
        vendorId: vendor?.id || null,
        currency: selectedCurrency,
    })
    if (!vendor) return null

    const estimatedYearlyRevenue = licenses?.totalRevenue || '-'

    const nextLicenseDate = licenses?.nextLicenseDate
        ? formatDate(new Date(licenses?.nextLicenseDate))
        : '-'

    return (
        <>
            <span>{customerData.name}</span>
            <span>{estimatedYearlyRevenue}</span>
            <span>{nextLicenseDate}</span>
            <span>-</span>
            <span>-</span>
        </>
    )
}
