import React from 'react'
import { Button } from 'react-bootstrap'

export interface CancelSubmitFooterProps {
    onClose: () => void
    onSubmit: () => void
    canSubmit?: boolean
    customSubmitText?: string
}

export default function CancelSubmitFooter(
    defaultProps: CancelSubmitFooterProps,
) {
    const { onClose, onSubmit, canSubmit, customSubmitText } = defaultProps

    return (
        <>
            <Button variant="light" onClick={onClose}>
                Cancel
            </Button>
            <Button onClick={onSubmit} disabled={!canSubmit}>
                {customSubmitText || 'Save'}
            </Button>
        </>
    )
}

CancelSubmitFooter.defaultProps = {
    canSubmit: true,
}
