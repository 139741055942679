import { Dropdown } from 'react-bootstrap'

import styled from 'styled-components'
import { primary } from 'utils/colors'

import { DropdownContainer } from '../Modals/Modals.styled'

export const ArticleCurrencyDropdownContainer = styled(DropdownContainer)`
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 15px;
    padding: 0;
    margin-bottom: 0.5rem;

    .missing-asset {
        color: ${primary.red};
    }

    :first-child {
        width: 100%;
    }
`

export const CurrencyDropdown = styled(Dropdown)`
    justify-content: end;
    justify-self: end;
    width: 100%;

    .dropdown-menu.show {
        min-width: 0;
        padding: 0;
    }
`
