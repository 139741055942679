import React from 'react'

import BootstrapTable from 'apps/shared/components/BootstrapTable'
import StatusIcon from 'apps/shared/components/Icons/StatusIcon'
import { TableWrapper } from 'apps/vendor/components/Tables/Tables.styled'

import {
    ActionsNeededCardContent,
    ActionsNeededCardWrapper,
    ActionsNeededIcon,
    IconAndTitleWrapper,
} from './ActionsNeededCard.styled'

type DashboardCardProps = {
    value: string
    description: string
}

export default function ActionsNeededCard(defaultProps: DashboardCardProps) {
    const { value, description } = defaultProps

    const dummyData = [
        {
            reason: 'Renewed',
            description: 'Licenses',
            number: <StatusIcon type="success" text="2" />,
        },
        {
            reason: 'Invitations',
            description: 'Pending',
            number: <StatusIcon type="warning" text="7" />,
        },
        {
            reason: 'Expired',
            description: 'Licenses',
            number: <StatusIcon type="danger" text="5" />,
        },
    ]

    const accessors = ['reason', 'description', 'number']

    return (
        <ActionsNeededCardWrapper>
            <IconAndTitleWrapper>
                <ActionsNeededIcon className="fa-regular fa-person-digging" />
                <p className="title">Actions Needed</p>
            </IconAndTitleWrapper>
            <ActionsNeededCardContent>
                <TableWrapper numberOfColumns={3}>
                    <BootstrapTable data={dummyData} accessors={accessors} />
                </TableWrapper>
            </ActionsNeededCardContent>
        </ActionsNeededCardWrapper>
    )
}
