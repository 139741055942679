import React, { useState, useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap'

import { addUser } from 'api/users'
import { PhoneInputField } from 'apps/shared/components/FormInputFields'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import Modal from 'apps/shared/components/Modals/Modal'
import { ErrorMessage, FormLabel } from 'apps/shared/shared.styled'
import useToast from 'hooks/useToast'
import isEmail from 'validator/lib/isEmail'

import { Checkbox, ModalWrapper } from '../Modals.styled'

export default function NewVendorUserModal(props: {
    onClose: () => void
    updateUserList: ({
        id,
        email,
        is_vendor_admin,
    }: {
        id: number
        email: string
        is_vendor_admin: boolean
    }) => void
}) {
    const { onClose, updateUserList } = props
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)
    const { errorToast, successToast } = useToast()
    const inputRef = useRef<HTMLInputElement>(null)
    const [canSubmit, setCanSubmit] = useState(true)

    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    useEffect(() => {
        const hasErrors = emailError !== '' || phoneNumberError !== ''

        const requieredFieldsSet =
            firstName !== '' && lastName !== '' && email !== ''

        setCanSubmit(!hasErrors && requieredFieldsSet)
    }, [email, emailError, firstName, lastName, phoneNumberError])

    const handleSubmit = () => {
        if (!canSubmit) {
            return
        }

        const userData = {
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: phoneNumber,
            is_vendor_admin: isAdmin,
        }

        addUser(userData)
            .then((response) => {
                const user = response.data

                updateUserList({
                    id: user.id,
                    email: user.email,
                    is_vendor_admin: user.is_vendor_admin,
                })
                successToast(`User has been added successfully!`)
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    return errorToast(`Please provide a valid email.`)
                }

                if (err.response.status === 403) {
                    return errorToast(`Missing permissions to add new users.`)
                }

                if (err.response.status === 409) {
                    return errorToast(`User with that email already exists.`)
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const handleSetEmail = (input: string) => {
        setEmail(input)
        setEmailError('')

        if (input && !isEmail(input)) {
            setEmailError('Please provide a valid email.')
        } else {
            setEmailError('')
        }
    }

    return (
        <Modal
            onClose={onClose}
            title="New"
            body={
                <ModalWrapper>
                    <FormLabel>First Name</FormLabel>
                    <Form.Control
                        {...{
                            ref: inputRef,
                            type: 'text',
                            value: firstName,
                            onChange: (e: any) => setFirstName(e.target.value),
                            onBlur: (e: any) =>
                                setFirstName(e.target.value.trim()),
                        }}
                    />
                    <FormLabel>Last Name</FormLabel>
                    <Form.Control
                        {...{
                            type: 'text',
                            value: lastName,
                            onChange: (e: any) => setLastName(e.target.value),
                            onBlur: (e: any) =>
                                setLastName(e.target.value.trim()),
                        }}
                    />
                    <FormLabel>Email Address</FormLabel>
                    <Form.Control
                        {...{
                            placeholder: 'name@example.com',
                            type: 'email',
                            value: email,
                            onChange: (e: any) =>
                                handleSetEmail(e.target.value),
                        }}
                    />
                    {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                    <FormLabel>
                        Phone number<span> - Optional</span>
                    </FormLabel>
                    <PhoneInputField
                        {...{
                            phoneNumber,
                            onChange: (phoneInput, errorMessage) => {
                                setPhoneNumber(phoneInput)
                                setPhoneNumberError(errorMessage)
                            },
                        }}
                    />
                    {phoneNumberError && (
                        <ErrorMessage>{phoneNumberError}</ErrorMessage>
                    )}
                    <Checkbox
                        id="isAdmin"
                        onChange={(e: any) => setIsAdmin(e.target.checked)}
                        label="Admin"
                    />
                </ModalWrapper>
            }
            footer={
                <CancelSubmitFooter
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    canSubmit={canSubmit}
                />
            }
        />
    )
}
