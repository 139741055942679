import { Dropdown } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const SideBar = styled.div`
    .collapsing {
        transition: none;
        width: 300px;
    }

    @media screen and (min-width: 576px) {
        box-sizing: border-box;
        border: 2px solid ${secondary.whisperWhite};
        border-top: none;
        border-left: none;
        position: relative;

        > * {
            padding: 0;
            height: 100%;
            width: 100%;
            overflow-y: auto;
        }

        > * > * {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            .navbar-nav {
                display: flex;
                flex-direction: column;
            }

            .navbar-brand {
                margin: 0;
                width: 100%;
                padding-top: 0;
            }

            .navbar-expand-xl .navbar-nav .nav-link {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    @media screen and (max-width: 575px) {
        > * {
            padding: 0;
            width: 100vw;
            z-index: 500;
            position: fixed;

            > * {
                background-color: white;
                position: relative;
                height: 100%;

                &:nth-child(2) {
                    overflow-y: auto;
                    height: 100vh;
                    > *:nth-child(2) {
                        min-height: 600px;
                    }
                }
            }
        }
    }
`

export const UserIcon = styled.div`
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5em;
    padding-right: 1em;
    padding-left: 1em;

    .icon {
        position: relative;
        height: 55px;
        width: 55px;
        min-width: 55px;
        min-height: 55px;
        user-select: none;

        font-size: 1.2em;
        font-weight: 300;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: ${secondary.lightLimeGreen};
        color: ${primary.darkGray};
        border-radius: 100%;
    }

    .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1em;
        color: ${primary.gray};
        font-weight: 200;
    }
`

export const NavRow = styled.div`
    width: 100%;
    margin-top: -5px;

    @media (max-width: 575px) {
        margin-top: -30px;
    }
    > div > div > a {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        position: relative;

        button {
            display: flex;
            height: 3em;
            padding: 1em 15px;
            margin: 5px 20px;
            position: relative;

            background-color: transparent;
            color: ${primary.lightGray};
            font-size: 1.2em;

            border: none;
            border-radius: 8px;

            align-items: flex-start;

            > * {
                position: absolute;
                left: 2.5em;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 500;
                color: ${primary.darkBlue};
                pointer-events: none;
            }
        }
        > .active {
            background-color: ${secondary.paleBlue};
        }
    }
`

export const TopBar = styled.div`
    height: 140px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: ${primary.darkBlue};
    object-fit: cover;

    > * {
        width: 200px;
        margin: 45px;
    }
`

export const StyledToggle = styled.button<{ open: boolean }>`
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 15px;
    z-index: 20;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;

    @media (min-width: 1200px) {
        display: none;
    }

    @media (max-width: 1199px) {
        left: 0;
    }

    @media (max-width: 575px) {
        right: 10px;
    }

    div {
        width: 2rem;
        height: 0.25rem;
        background-color: ${({ open }) =>
            open ? `${secondary.lightGray}` : `${primary.darkBlue}`};
        border-radius: 10px;
        transform-origin: 1.8px;
        transition: all 0.3s linear;

        &:nth-child(1) {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        &:nth-child(2) {
            transform: ${({ open }) =>
                open ? 'translateX(100%)' : 'translateX(0)'};
            opacity: ${({ open }) => (open ? 0 : 1)};
        }

        &:nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`

export const UserBadge = styled.div`
    height: 100%;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
    box-sizing: border-box;

    .badge-line {
        margin-left: -1%;
    }

    @media screen and (max-width: 575px) {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: fit-content;
        box-sizing: border-box;

        > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`

export const SettingsDropdown = styled(Dropdown)`
    width: 100%;

    .badge-line {
        width: 100%;
        margin-bottom: 0;
    }

    &:hover {
        .icon {
            background-color: ${secondary.shadedGreen};
            &:active {
                background-color: ${secondary.lighterGreen};
            }
        }

        .name {
            color: ${primary.darkGray};
            text-decoration: underline;
            &:active {
                color: ${primary.gray};
            }
        }
    }

    > .dropdown-toggle {
        width: 100%;
        background: none;
        border: none;
        color: ${primary.darkGray};
        font-size: 1.2em;
        padding: 0;
        padding-top: 1.5em;

        ::after {
            display: none;
        }

        :active,
        :focus {
            background: none;
            color: ${primary.lightGray};
        }
    }

    > .dropdown-menu {
        background-color: ${secondary.almostWhiteGray};
        box-shadow: 0 0 5px 0 #00000033;
    }

    .dropdown-menu.show {
        position: absolute;
        top: -73%;
        right: 0;
    }
`
