import React from 'react'
import { Dropdown } from 'react-bootstrap'

import { DropdownContainer } from './ItemSelectorDropdown.styled'

interface DropdownProps {
    preselectedValue: string | undefined
    items: { code: string; name: string }[]
    onClick: (value: any) => void
    placeholder?: string
    disabled?: boolean
}

export default function ItemSelectorDropdown(defaultProps: DropdownProps) {
    const { preselectedValue, items, onClick, placeholder, disabled } =
        defaultProps

    const preselectedItem = items.find((item) => item.code === preselectedValue)

    return (
        <DropdownContainer>
            <Dropdown>
                <Dropdown.Toggle
                    variant="outline-dark"
                    id={items[0].name}
                    disabled={disabled}
                >
                    {preselectedItem?.name || placeholder || 'Options'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {items.map((item) => (
                        <Dropdown.Item
                            key={item.code}
                            onClick={() => onClick(item.code)}
                        >
                            {item.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </DropdownContainer>
    )
}
