import React from 'react'

import { ConfirmModal } from 'apps/shared/components/Modals'

interface partnerType {
    id: number
    name: string
}

function DeletePartnerBody(props: { vendor: partnerType }) {
    const { vendor } = props

    return <p>Are you sure you want to remove the partner {vendor.name}?</p>
}

export default function DeletePartnerConfirmationModal(props: {
    onClose: () => void
    onSubmit: () => void
    vendor: partnerType
}) {
    const { onClose, onSubmit, vendor } = props

    return (
        <ConfirmModal
            body={<DeletePartnerBody vendor={vendor} />}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    )
}
